let aiProjectMap = {
    "ai": {
        "leadgen": {
          "meta": {
            "date":"Date",
            "spends": "Spends",
            "impressions": "Impressions",
            "clicks": "Clicks",
            "cpm": "CPM",
            "cpcl": "CPC",
            "ctr": "CTR",
            "conversions": "Leads",
            "cpc": "CPL",
          },
          "googleAds": {
            "campaignName":"Campaign Name",
            "costMicros": "Spends",
            "impressions": "Impressions",
            "clicks": "Clicks",
            "cpm": "CPM",
            "cpcl": "CPC",
            "ctr": "CTR",
            "conversions": "Leads",
            "cpc": "CPL",
          },
        },
        "ecommerce": {
            "meta": {
              "date":"Date",
              "spends": "Spends",
              "impressions": "Impressions",
              "clicks": "Clicks",
              "cpm": "CPM",
              "cpcl": "CPC",
              "ctr": "CTR",
              "purchases": "Purchases",
              "roas": "ROAS",
            },
            "googleAds": {
              "campaignName":"Campaign Name",
              "costMicros": "Spends",
              "impressions": "Impressions",
              "clicks": "Clicks",
              "cpm": "CPM",
              "cpcl": "CPC",
              "ctr": "CTR",
             "purchases": "Purchases",
              "roas": "ROAS",
            },
          },
        "default": {
          "meta": {
            "date":"Date",
            "spends": "Spends",
            "impressions": "Impressions",
            "clicks": "Clicks",
            "conversions": "Conversions",
            "cpm":"CPM",
            "cpcl": "CPC", 
            "ctr": "CTR",
            "ROAS": "ROAS",
          },
          "googleAds": {
            "campaignName":"Campaign Name",
            "costMicros": "Spends",
            "impressions": "Impressions",
            "clicks": "Clicks",
            "conversions": "Conversions",
            "cpm":"CPM",
            "cpcl": "CPC", 
            "ctr": "CTR",
            "ROAS": "ROAS",
          },
        }
      },
}

export default aiProjectMap